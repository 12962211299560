<template>
  <el-dialog :show-close="false" width="790px" visible custom-class="contactSet-modal">
    <template #title>
      <div class="d-flex justify-content-between">
        <div>
          {{ isEdit ? $t('contactsSetModal.title.edit') : $t('contactsSetModal.title.create') }}
        </div>
        <div class="d-flex gap-2">
          <el-dropdown
            v-if="isEdit"
            class="d-flex justify-content-center"
            trigger="click"
            placement="bottom-start"
            @command="handleAction"
          >
            <Button type="icon" class="p-0">
              <KebabIcon />
            </Button>

            <el-dropdown-menu>
              <div class="d-flex flex-column">
                <el-dropdown-item :command="ACTIONS.CONTACTSET_REMOVE">
                  <div
                    class="w-100 d-flex flex-row justify-content-center align-items-center gap-2 text-typography-primary"
                  >
                    <TrashCanIcon />
                    <p>
                      {{ $t('contactsSetModal.workplace.actions.remove') }}
                    </p>
                  </div>
                </el-dropdown-item>
              </div>
            </el-dropdown-menu>
          </el-dropdown>
          <Button type="icon" class="p-0" @click="$emit('close')">
            <CloseIcon />
          </Button>
        </div>
      </div>
    </template>

    <el-form
      ref="contactSetForm"
      label-position="top"
      class="d-flex flex-column gap-4"
      :model="contactSet"
      :rules="validationRules"
      :inline-message="true"
    >
      <div class="d-flex gap-6">
        <div class="section">
          <ContactSetInfo
            :first-name="contactSet.firstName"
            :last-name="contactSet.lastName"
            :office-name="contactSet.officeName"
            :type="contactSet.type"
            :phones="contactSet.phones"
            :emails="contactSet.emails"
            :gender="contactSet.gender"
            :note="contactSet.note"
            :custom-errors="customErrors"
            @updated="infoUpdated"
            @phoneAdd="infoAddPhone"
            @phoneUpdate="infoPhoneUpdate"
            @phoneRemove="infoPhoneRemove"
            @emailAdd="infoEmailAdd"
            @emailUpdate="infoEmailUpdate"
            @emailRemove="infoEmailRemove"
          >
          </ContactSetInfo>
          <el-divider />

          <ContactSetWorkplaces
            :workplace-active-index="workplaceIndexSelected"
            :works-at="contactSet.worksAt"
            :selected-works-at-business="selectedWorksAtBusiness"
            @selected="workplaceSelected"
            @added="workplaceAdded"
            @updated="workplaceUpdated"
            @removed="workplaceRemoved"
          ></ContactSetWorkplaces>
        </div>
        <div class="section">
          <ContactSetWorkWith
            :works-at="workplaceIndexSelected !== -1 ? contactSet.worksAt[workplaceIndexSelected - 1] : null"
            @updated="worksWithUpdated"
          >
          </ContactSetWorkWith>
        </div>
      </div>
    </el-form>
    <div class="footer">
      <Button type="secondary" @click="$emit('close')">
        {{ $t('contactsSetModal.actions.cancel') }}
      </Button>
      <Button v-if="contactSet.worksAt.length > 0 || !isEdit" :disabled="isDisabled" @click="onContactSetSubmit">
        {{ $t('contactsSetModal.actions.save') }}
      </Button>
      <Button v-else type="danger" @click="onContactSetDelete">
        {{ $t('contactsSetModal.actions.delete') }}
      </Button>
    </div>
  </el-dialog>
</template>

<script>
import { getCurrentInstance, reactive, ref, watch } from 'vue';
import { either, isEmpty, isNil, reject } from 'ramda';

import { Button } from '@/modules/core';
import { useTenancy } from '@/modules/auth';
import { KebabIcon, TrashCanIcon, CloseIcon } from '@/assets/icons';

import { ContactSetInfo, ContactSetWorkplaces, ContactSetWorkWith } from './components';
import { CONTACT_TYPE, INITIAL_PHONE, INITIAL_EMAIL } from './components/contactSetInfo.vue';
import { useContactSetCreate, useContactSetDelete, useContactSetPatch } from './composition/contactSet';
import { COUNTRY_CODE } from './components/fields/phoneField.vue';

const ACTIONS = {
  CONTACTSET_REMOVE: 'remove',
};

export default {
  name: 'ContactSetEditModal',
  components: {
    Button,
    KebabIcon,
    TrashCanIcon,
    CloseIcon,
    ContactSetInfo,
    ContactSetWorkplaces,
    ContactSetWorkWith,
  },
  props: {
    clarityBusinessId: { type: String, required: true },
    isEdit: { type: Boolean, required: false, default: false },
    contact: { type: Object, required: false, default: () => ({}) },
  },
  setup(props) {
    const root = getCurrentInstance().proxy;
    const workplaceIndexSelected = ref(1);
    const transformCountryCode = (countryCode) => {
      if (countryCode === '+972') {
        return COUNTRY_CODE.IL;
      } else if (countryCode === '+1') {
        return COUNTRY_CODE.US;
      }

      return COUNTRY_CODE.IL;
    };

    const contactSet = reactive({
      id: props.contact.id,
      firstName: props.contact.firstName,
      lastName: props.contact.lastName,
      officeName: props.contact.officeName,
      type: props.contact.type || CONTACT_TYPE.CONTACT,
      phones: props.contact.phones?.length
        ? props.contact.phones.map((phone) => ({
            number: phone.number,
            countryCode: transformCountryCode(phone.countryCode),
            extension: phone.extension,
          }))
        : [{ ...INITIAL_PHONE }],
      emails: props.contact.emails?.length ? props.contact.emails.map(({ email }) => email) : [INITIAL_EMAIL],
      gender: props.contact.gender,
      note: props.contact.note,
      worksAt: props.contact.worksAt?.length
        ? props.contact.worksAt.map((workplace) => ({
            id: workplace.id,
            name: workplace.name,
            type: workplace.type,
            role: workplace.role,
            worksWith: [...workplace.worksWith?.filter(({ id }) => id !== props.clarityBusinessId)],
          }))
        : [
            {
              id: null,
              name: null,
              type: null,
              role: null,
              worksWith: [],
            },
          ],
    });
    const customErrors = ref({
      phones: ['', '', ''],
      emails: ['', '', ''],
    });

    const { currentTenant } = useTenancy();
    const { createContactSet, errors: createErrors, onDone: onCreateDone } = useContactSetCreate();
    const { patchContactSet, errors: updateErrors, onDone: onPatchDone } = useContactSetPatch();
    const { deleteContactSet } = useContactSetDelete();

    onCreateDone(() => root.$emit('close'));
    onPatchDone(() => root.$emit('close'));

    watch(
      [createErrors, updateErrors],
      ([newCreateErrors, newUpdateErrors]) => {
        customErrors.value = {
          phones: ['', '', ''],
          emails: ['', '', ''],
        };

        [newCreateErrors, newUpdateErrors].forEach((error) => {
          error?.phones?.forEach((errorPhone) => {
            const index = contactSet.phones.findIndex(
              (contactPhone) => contactPhone.number.replace(/-/g, '') === errorPhone
            );
            if (index !== -1) {
              customErrors.value.phones[index] = root.$t('contactsSetModal.validationErrors.phone.conflict');
            }
          });
          error?.emails?.forEach((errorEmail) => {
            const index = contactSet.emails.findIndex((contactEmail) => contactEmail === errorEmail);
            if (index !== -1) {
              customErrors.value.emails[index] = root.$t('contactsSetModal.validationErrors.email.conflict');
            }
          });
        });
      },
      { deep: true }
    );

    return {
      currentTenant,
      workplaceIndexSelected,
      contactSet,
      createContactSet,
      patchContactSet,
      deleteContactSet,
      customErrors,
      createErrors,
    };
  },
  data() {
    const validationRules = {
      'phones[0].number': [
        { validator: this.validateOneChannel, trigger: ['blur'] },
        { validator: this.validatePhone, trigger: ['change', 'blur'] },
      ],
      'phones[1].number': [
        { validator: this.validateOneChannel, trigger: ['blur'] },
        { validator: this.validatePhone, trigger: ['change', 'blur'] },
      ],
      'phones[2].number': [
        { validator: this.validateOneChannel, trigger: ['blur'] },
        { validator: this.validatePhone, trigger: ['change', 'blur'] },
      ],
      'emails[0]': [
        { validator: this.validateOneChannel, trigger: ['blur'] },
        {
          pattern: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
          message: this.$t('contactsSetModal.validationErrors.email.invalid'),
          trigger: ['blur'],
        },
      ],
      'emails[1]': [
        { validator: this.validateOneChannel, trigger: ['blur'] },
        {
          pattern: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
          message: this.$t('contactsSetModal.validationErrors.email.invalid'),
          trigger: ['blur'],
        },
      ],
      'emails[2]': [
        { validator: this.validateOneChannel, trigger: ['blur'] },
        {
          pattern: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
          message: this.$t('contactsSetModal.validationErrors.email.invalid'),
          trigger: ['blur'],
        },
      ],
      officeName: [
        {
          required: true,
          message: this.$t('contactsSetModal.validationErrors.officeName.required'),
          trigger: ['blur'],
        },
      ],
      firstName: [
        {
          required: true,
          message: this.$t('contactsSetModal.validationErrors.firstName.required'),
          trigger: ['blur'],
        },
      ],
    };
    return {
      ACTIONS,
      validationRules,
    };
  },
  computed: {
    isDisabled() {
      return (
        !this.contactSet.worksAt[0].id ||
        (!this.contactSet.firstName && !this.contactSet.officeName) ||
        (!this.contactSet.phones[0]?.number && !this.contactSet.emails[0])
      );
    },
    selectedWorksAtBusiness() {
      return this.contactSet.worksAt.filter((workplace) => workplace.id).map((workplace) => workplace);
    },
  },
  methods: {
    validateOneChannel(rule, value, callback) {
      if (
        !this.contactSet.emails.some((email) => email.length > 0) &&
        this.contactSet.phones.filter((phone) => phone.number?.length).length < 1
      ) {
        callback(new Error(this.$t('contactsSetModal.validationErrors.phoneOrEmail.required')));
      } else {
        callback();
      }
    },
    validatePhone(rule, value, callback) {
      if (value === '') {
        callback();
        return;
      }

      const index = rule.field.match(/\[(\d+)\]/);
      const countryCode = this.contactSet.phones[index[1]]?.countryCode;
      const pattern =
        countryCode === COUNTRY_CODE.IL
          ? /^(02-?\d{7})$|(03-?\d{7})$|(04-?\d{7})$|(08-?\d{7})$|(09-?\d{7})$|(05\d-?\d{7})$|(07\d-?\d{7})$|(1599-?\d{3}-?\d{3})$|(1700-?\d{3}-?\d{3})$|(180\d-?\d{3}-?\d{3})$|(19\d{2}-?\d{3}-?\d{3})$|(\*\d{4})$/
          : /^\d{10}$/;

      if (!pattern.test(value)) {
        callback(new Error(this.$t('contactsSetModal.validationErrors.phone.invalid')));
        return;
      }
      callback();
    },
    async handleAction(command) {
      switch (command) {
        case ACTIONS.CONTACTSET_REMOVE:
          await this.onContactSetDelete();
          break;
      }
    },
    addCustomError({ phones = [], emails = [] }) {
      this.contactSet.phones.forEach((phone, index) => {
        const conflictingPhone = phones.find((number) => number === phone.number);
        if (conflictingPhone) {
          this.customErrors.phones[index] = this.$t('contactsSetModal.phone.conflict');
        }
      });
      this.contactSet.emails.forEach((email, index) => {
        const conflictingEmail = emails.find((address) => address === email);
        if (conflictingEmail) {
          this.customErrors.emails[index] = this.$t('contactsSetModal.email.conflict');
        }
      });
    },
    clearCustomError(field, index) {
      if (field && index) {
        this.customErrors[field].splice(index, 1, '');
      } else {
        this.customErrors = {
          phones: ['', '', ''],
          emails: ['', '', ''],
        };
      }
    },
    workplaceSelected(workplaceIndex) {
      this.workplaceIndexSelected = workplaceIndex;
    },
    workplaceAdded() {
      const newLength = this.contactSet.worksAt.push({
        id: null,
        name: null,
        role: null,
        worksWith: [],
      });
      this.workplaceIndexSelected = newLength;
    },
    workplaceUpdated({ index, newData }) {
      this.contactSet.worksAt.splice(index, 1, newData);
    },
    workplaceRemoved(index) {
      this.contactSet.worksAt.splice(index, 1);
      if (this.workplaceIndexSelected === index + 1) {
        this.workplaceIndexSelected = 1;
      } else if (!this.contactSet.worksAt.length) {
        this.workplaceIndexSelected = -1;
      }
    },
    worksWithUpdated(business) {
      const index = this.contactSet.worksAt[this.workplaceIndexSelected - 1].worksWith.findIndex(
        (b) => b.id === business.id
      );
      if (index === -1) {
        this.contactSet.worksAt[this.workplaceIndexSelected - 1].worksWith.push(business);
      } else {
        this.contactSet.worksAt[this.workplaceIndexSelected - 1].worksWith.splice(index, 1);
      }
    },
    infoUpdated({ field, value }) {
      this.contactSet[field] = value;

      if (field === 'type' && value === CONTACT_TYPE.CONTACT) {
        this.contactSet.officeName = null;
      } else if (field === 'type' && value === CONTACT_TYPE.OFFICE) {
        this.contactSet.firstName = null;
        this.contactSet.lastName = null;
        this.contactSet.gender = null;
      }
    },
    infoAddPhone() {
      this.contactSet.phones.push({ ...INITIAL_PHONE });
    },
    infoPhoneUpdate({ index, newData }) {
      this.contactSet.phones.splice(index, 1, newData);
      this.clearCustomError('phones', index);
    },
    infoPhoneRemove(index) {
      this.contactSet.phones.splice(index, 1);
      this.clearCustomError('phones', index);
    },
    infoEmailAdd() {
      this.contactSet.emails.push(INITIAL_EMAIL);
    },
    infoEmailUpdate({ index, newData }) {
      this.contactSet.emails.splice(index, 1, newData);
      this.clearCustomError('emails', index);
    },
    infoEmailRemove(index) {
      this.contactSet.emails.splice(index, 1);
      this.clearCustomError('emails', index);
    },
    async onContactSetSubmit() {
      await this.$refs.contactSetForm.validate(async (valid) => {
        const countryCode = (phone) => {
          if (phone.countryCode === COUNTRY_CODE.IL) {
            return '+972';
          } else if (phone.countryCode === COUNTRY_CODE.US) {
            return '+1';
          }
          return null;
        };
        if (valid) {
          const createParams = {
            businessId: this.clarityBusinessId,
            type: this.contactSet.type,
            firstName: this.contactSet.firstName,
            lastName: this.contactSet.lastName,
            officeName: this.contactSet.officeName,
            gender: this.contactSet.gender,
            note: this.contactSet.note,
            phones: this.contactSet.phones
              .filter((phone) => phone.number?.length)
              .map((phone) =>
                reject(either(isNil, isEmpty), {
                  extension: phone.extension,
                  number: phone.number.replace(/-/g, ''),
                  countryCode: countryCode(phone),
                })
              ),
            emails: this.contactSet.emails.filter((email) => email?.length).map((email) => ({ email })),
            worksAt: this.contactSet.worksAt.map((workplace) =>
              reject(either(isNil, isEmpty), {
                id: workplace.id,
                role: workplace.role,
                worksWith: workplace.worksWith.map((worksWith) => worksWith.id),
              })
            ),
          };

          if (this.isEdit) {
            await this.patchContactSet({ id: this.contactSet.id, data: reject(either(isNil, isEmpty), createParams) });
          } else {
            await this.createContactSet({
              createParams: reject(either(isNil, isEmpty), createParams),
            });
          }
        }
      });
    },
    async onContactSetDelete() {
      this.$confirm(this.$t('contactsSet.confirmation.delete.body'), this.$t('contactsSet.confirmation.delete.title'), {
        confirmButtonText: this.$t('contactsSet.table.actions.delete'),
        confirmButtonClass: 'el-button--danger',
        cancelButtonText: this.$t('cancel'),
        cancelButtonClass: 'el-button--secondary',
      })
        .then(async (confirm) => {
          if (confirm) {
            await this.deleteContactSet({ id: this.contactSet.id }, { refetchQueries: ['contactSets'] });
            this.$emit('close');
          }
        })
        .catch(() => null);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/stylesheets/scss/global';

.section {
  flex: 1;
}

.footer {
  height: 40px;
  margin-top: 32px;
  display: flex;
  justify-content: flex-end;
}

:deep(.contactSet-modal .el-dialog__header) {
  padding: 16px;
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;

  .el-dialog__title {
    font-size: 24px;
    line-height: 28px;
  }

  button {
    width: 28px;
    height: 28px;
    &:hover {
      background-color: $secondary;
      border-radius: 4px;
    }

    i {
      font-size: 20px;
      color: $black;
    }
  }
}

:deep(.contactSet-modal .el-dialog__body) {
  padding: 0px 20px 20px 20px;
}
</style>
